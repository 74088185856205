import styled from '@mui/material/styles/styled';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import Logo from '../../components/Logo';
import Page from '../../components/Page';
import AdditionalInfoSection from '../../sections/otp-auth/AdditionalInfoSection';
import OtpRegSection from '../../sections/otp-auth/OtpRegSection';
import VerifyOtpSection from '../../sections/otp-auth/VerifyOtpSection';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const OtpRegistration = () => {
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    fullName: '',
    email: '',
    mobileno: '',
    otp: '',
    token: '',
  });
  const makeRequest = (newData) => {
    // setPage(1);
  };
  const handleNextStep = (newData, finalStep = false) => {
    setData({ ...data, ...newData });
    if (finalStep) {
      makeRequest(newData);
      return;
    }
    setPage(page + 1);
  };
  const handlePrevStep = (newData) => {
    setData({ ...data, ...newData });
    setPage(page - 1);
  };
  const stepData = [
    <OtpRegSection next={handleNextStep} data={data} setData={setData} />,
    <VerifyOtpSection next={handleNextStep} prev={handlePrevStep} data={data} setData={setData} />,
    <AdditionalInfoSection
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      setPage={setPage}
      setData={setData}
    />,
  ];

  return (
    <>
      {isLoading && <LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
      <Page title="Login">
        <RootStyle>
          <HeaderStyle>
            <Logo />
          </HeaderStyle>
          {stepData[page]}
        </RootStyle>
      </Page>
    </>
  );
};

export default OtpRegistration;
