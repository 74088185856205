import Typography from '@mui/material/Typography';

const FAQS_ARRAY = [
  // {
  //   panel: 'panel1',
  //   category: 'SigningUpOnPB',
  //   heading: 'What is Photonboxx?',
  //   detail:
  //     'Photonboxx is an online power backup monthly subscription platform giving power backup on a use and pay basis.',
  // },
  // {
  //   panel: 'panel2',
  //   category: 'SigningUpOnPB',
  //   heading: 'How does renting work?',
  //   detail:
  //     'Photonboxx works with a simple motto of change as your need evolves. We provide a compact home UPS with a built-in battery system on rent at affordable prices giving zero hassle of owning the system.',
  // },
  // {
  //   panel: 'panel3',
  //   category: 'SigningUpOnPB',
  //   heading: 'Do I have to rent the battery and the inverter separately?',
  //   detail:
  //     'No, the system we provide is an integrated UPS with a built-in Li-ion battery where the customer rents the whole setup and not individually.',
  // },
  {
    panel: 'panel4',
    category: 'Subscription',
    heading: 'What is the minimum subscription period?',
    detail:
      'The minimum subscription period is of 3 months. If you want to try out our services, we offer a commitment-free 15-day trial period as well.',
  },
  {
    panel: 'panel5',
    category: 'Subscription',
    heading: 'How does this subscription work?',
    detail: (
      <>
        <Typography>
          You can request the subscription through our homepage and our team will reach out to you or you can download
          our Android/IOS app and sign up directly. <br /> You choose your subscription plan and do an e-KYC.
          <br /> Upon successful verification, we initiate the delivery of the product. <br /> Our installation expert
          will install the product and you are good to go.
        </Typography>
      </>
    ),
  },
  {
    panel: 'panel7',
    category: 'Subscription',
    heading: 'When and how do I pay for the deposit?',
    detail:
      'The customer has to pay the deposit after successful delivery of the product through the Photonboxx mobile app.',
  },
  {
    panel: 'panel8',
    category: 'Subscription',
    heading: 'Can I transfer my subscription to someone else?',
    detail: (
      <>
        <Typography>
          Yes, you can always transfer your subscription to another person. Kindly raise a ticket through the app or
          reach out to us on{' '}
          <a href="mailto:support@photonboxx.com" style={{ textDecoration: 'none', color: '#3366FF' }}>
            support@photonboxx.com
          </a>{' '}
        </Typography>
      </>
    ),
  },
  {
    panel: 'panel9',
    category: 'Subscription',
    heading: 'How can I upgrade my subscription tenure?',
    detail: (
      <>
        <Typography>
          You can raise a ticket in the contact section if you wish to increase your commitment period anytime. Your
          monthly invoice will reflect adjusted rent on a pro-rata basis. <br /> Remember, longer tenure = lower
          rentals!
        </Typography>
      </>
    ),
  },
  {
    panel: 'panel10',
    category: 'Subscription',
    heading: 'What if the system isn’t delivered on the booked time slot?',
    detail:
      'If the user does not receive the system within the time slot, an earliest time slot will be allocated to the user as soon as possible. Alternatively, you can reach out to us or raise a ticket if the same is not allocated with 24 hours.',
  },
  {
    panel: 'panel11',
    category: 'Subscription',
    heading: 'When does the subscription end?',
    detail:
      'The subscription auto renews every month till you cancel. You can close the subscription anytime as per our flexi tenure policy. Kindly refer our terms and conditions for the same.',
  },
  {
    panel: 'panel12',
    category: 'Subscription',
    heading: 'How can I cancel my subscription before the end of my minimum tenure?',
    detail: `We always hope to have a long relationship with you. Although, if you wish to close your subscription before the end date of the subscription plan, you can raise a ticket and your subscription will end within 7 days of the request based on return pickup slot availability.
    However, an early closure charge would be applicable in this case. To know more you can refer Terms and conditions.`,
  },
  {
    panel: 'panel13',
    category: 'Subscription',
    heading: 'What is the penalty if I damage the product?',
    detail: (
      <>
        <Typography>
          If in any case, you damage the product you have to inform Photonboxx about the damage by contacting us through
          our customer support. A technician will come to inspect the system and provide you a damage report within 2
          working days. <br />
          If case the system is not operable due to the damage, we shall replace the system subject to damage charges as
          per the Damage policy mentioned on our terms and conditions page.
        </Typography>
      </>
    ),
  },
  {
    panel: 'panel14',
    category: 'Subscription',
    heading: 'What are the charges applied if failed to pay till the due date?',
    detail:
      'If the user fails to pay the due amount after the due date, a 10% late payment fee would be charged against the invoice value.',
  },
  {
    panel: 'panel15',
    category: 'Subscription',
    heading: 'What will be my start date for the billing cycle?',
    detail:
      'For billing cycle, the start date will be the date of completion of installation of the system at the property.',
  },
  {
    panel: 'panel16',
    category: 'Subscription',
    heading: 'Can I change my bill cycle?',
    detail: (
      <>
        <Typography>
          No, you cannot change your billing cycle, for further queries you can reach us at <br />
          <a href="mailto:support@photonboxx.com" style={{ textDecoration: 'none', color: '#3366FF' }}>
            support@photonboxx.com
          </a>{' '}
        </Typography>
      </>
    ),
  },
  {
    panel: 'panel17',
    category: 'Subscription',
    heading: 'Incorrect charge on the invoice. What can I do?',
    detail: (
      <>
        <Typography>
          For any correction and invoice-related inquiry or issue, you can reach us at <br />
          <a href="mailto:support@photonboxx.com" style={{ textDecoration: 'none', color: '#3366FF' }}>
            support@photonboxx.com
          </a>{' '}
        </Typography>
      </>
    ),
  },
  {
    panel: 'panel18',
    category: 'Subscription',
    heading: ' How much deposit do I have to pay?',
    detail:
      'We charge a nominal amount of rupees two thousand as a security deposit to cover any discrepancies in payments or extra services we provide. This deposit is 100% refundable unless there are pending dues or damage to the system.',
  },
  {
    panel: 'panel16',
    category: 'InvoicingPaymentsAndrefunds',
    heading: 'What will be my start date for the billing cycle?',
    detail:
      'The billing cycle, start date will be the date of completion of the installation of the system at the property.',
  },
  {
    panel: 'panel17',
    category: 'InvoicingPaymentsAndrefunds',
    heading: 'can I change my bill cycle?',
    detail: (
      <>
        <Typography component="span" variant="body1">
          No, you cannot change your billing cycle, for further queries you can reach us at <br />
          <a href="mailto:support@photonboxx.com" style={{ textDecoration: 'none', color: '#3366FF' }}>
            support@photonboxx.com
          </a>{' '}
        </Typography>
      </>
    ),
  },
  {
    panel: 'panel18',
    category: 'InvoicingPaymentsAndrefunds',
    heading: 'Incorrect charge on the invoice. What can I do?What is the billing cycle?',
    detail: (
      <Typography component="span" variant="body1">
        For any correction and invoice-related inquiry or issue, you can reach us at{' '}
        <a href="mailto:support@photonboxx.com" style={{ textDecoration: 'none', color: '#3366FF' }}>
          support@photonboxx.com
        </a>
      </Typography>
    ),
  },
  // {
  //   panel: 'panel19',
  //   category: 'InvoicingPaymentsAndrefunds',
  //   heading: 'When and where will I receive my refund?',
  //   detail:
  //     'A refund will be initiated once the system is uninstalled from the property and will reflect in the user’s source account within 5-7 working days.',
  // },
  // {
  //   panel: 'panel20',
  //   category: 'InvoicingPaymentsAndrefunds',
  //   heading: 'What are the available modes of payment?',
  //   detail: 'WCard, UPI, and net banking are modes of payment.',
  // },
  // {
  //   panel: 'panel21',
  //   category: 'InvoicingPaymentsAndrefunds',
  //   heading: 'How secure are my payment details and personal information?',
  //   detail: 'To understand better you can refer to the privacy policy.',
  // },
  // {
  //   panel: 'panel22',
  //   category: 'InvoicingPaymentsAndrefunds',
  //   heading: 'Payment failed but the money has been deducted from the account, What should I do?',
  //   detail:
  //     'If the payment fails and money has been deducted from your account you can reach us at support@photonboxx.com ',
  // },
  // {
  //   panel: 'panel23',
  //   category: 'InvoicingPaymentsAndrefunds',
  //   heading: 'when do I have to pay for the deposit?',
  //   detail: 'The deposit has to be paid after the delivery of the system has been completed.',
  // },
  // {
  //   panel: 'panel24',
  //   category: 'InvoicingPaymentsAndrefunds',
  //   heading: 'My card was declined during the payment process, what can I do?',
  //   detail: 'Payment can be reinitiated if in case your card has declined.',
  // },
  // {
  //   panel: 'panel25',
  //   category: 'InvoicingPaymentsAndrefunds',
  //   heading: 'What is the return policy of the system?',
  //   detail: 'For a better understanding, you can refer to the return policy of photonboxx.',
  // },
  // {
  //   panel: 'panel26',
  //   category: 'InvoicingPaymentsAndrefunds',
  //   heading:
  //     'I have received a confirmation on the deposit, but the amount has not been credited to my bank account?',
  //   detail:
  //     'After the deposit has been initiated it will take 5-7 working days to transfer to your bank account. Although if not credited still you can reach us at support@photonboxx.com.',
  // },
  {
    panel: 'panel27',
    category: 'serviceAndMaintenanceRelocation',
    heading: 'What if the product needs replacement?',
    detail:
      'We stringently check the system before the installation. Still, if there is any system specific issue, you can request a replacement from the support section',
  },
  {
    panel: 'panel28',
    category: 'serviceAndMaintenanceRelocation',
    heading: 'What if the product gets dusty and dirty over time?',
    detail:
      'It is recommended to keep the system in a clean environment. However, the user can call for service requests after completion of a minimum of 6 months or if the dust has affected the internal operation of the system in any way.',
  },
  {
    panel: 'panel29',
    category: 'serviceAndMaintenanceRelocation',
    heading: 'How can I avail relocation service?',
    detail:
      'For relocation, the user can request the same by raising a ticket on the mobile application. For further details, please refer to our relocation policy.',
  },
  {
    panel: 'panel30',
    category: 'serviceAndMaintenanceRelocation',
    heading: 'How many times I can avail free relocation?',
    detail:
      'Users can avail of relocation as many times as possible within photonboxx serviceable areas. There should be a minimum of 60 days peiod between two consecutive relocations.',
  },
  {
    panel: 'panel31',
    category: 'serviceAndMaintenanceRelocation',
    heading: 'Can I relocate the product on my own?',
    detail: (
      <>
        <Typography>
          No, the user can always request a free relocation as we would always want your experience to be hassle-free.
          Furthermore, there is always a risk of personal safety as well as system mishandling.
          <br />
          As the system is a property of the company, it is mandatory to inform us prior to relocation.
        </Typography>
        ,
      </>
    ),
  },
  {
    panel: 'panel32',
    category: 'serviceAndMaintenanceRelocation',
    heading: 'What are the precautions that I have to take care of?',
    detail: (
      <>
        <Typography>
          You should ensure the product is always out of reach from children. The product should always be kept indoors
          and away from direct sunlight. <br /> Make sure that the system will always be far from humid spaces such as
          washrooms.
        </Typography>
      </>
    ),
  },
  {
    panel: 'panel34',
    category: 'serviceAndMaintenanceRelocation',
    heading: 'What if the claimed backup is not attained?',
    detail: (
      <>
        <Typography>
          We always try to give the best results possible. Also, we do offer a trial period to all our customers to
          experience the product. However, if you are not satisfied with the system’s performance after your regular
          subscription starts, feel free to contact us and we will try our best to solve the problem. <br /> If you do
          not feel satisfied with the resolution, you can cancel the subscription as per our Cancellation and Refunds
          Policy.
        </Typography>
      </>
    ),
  },
  {
    panel: 'panel35',
    category: 'deliveryAndInstallation',
    heading: 'What is the approximate time of delivery?',
    detail: 'The delivery of the product can take up to 7-14 days after the confirmation of the order.',
  },
  {
    panel: 'panel36',
    category: 'deliveryAndInstallation',
    heading: 'What if I got a damaged product?',
    detail:
      'We stringently check our systems before dispatch. If a damaged product is found at the time of delivery, replacement order for the same will be initiated immediately.',
  },
  {
    panel: 'panel37',
    category: 'deliveryAndInstallation',
    heading: 'Who will deliver and install the product for me?',
    detail: 'We have a team of trained installers and a delivery personnel who perform these tasks.',
  },
  {
    panel: 'panel38',
    category: 'deliveryAndInstallation',
    heading: `How can I reschedule the delivery?`,
    detail: (
      <Typography>
        The Delivery can be rescheduled by reaching us at
        <a href="mailto:support@photonboxx.com" style={{ textDecoration: 'none', color: '#3366FF' }}>
          {' '}
          support@photonboxx.com
        </a>
      </Typography>
    ),
  },
  {
    panel: 'panel39',
    category: 'deliveryAndInstallation',
    heading: `What if I wasn't at home at the time of delivery?`,
    detail:
      'If the registered user is not present at the time of delivery, a delivery re-attempt will be initiated as per your future availability.',
  },
  {
    panel: 'panel40',
    category: 'deliveryAndInstallation',
    heading: 'Do I have to submit any additional documents on delivery?',
    detail: 'No, there are no additional documents required at the time of delivery.',
  },
  {
    panel: 'panel41',
    category: 'cancellationAndRefunds',
    heading: 'When and where will I receive my refund?',
    detail:
      'Refund will be initiated once the system is uninstalled from the property and checked in our facility. The initiated amount will reflect in your preferred mode of payment in 5-7 working days.',
  },
  {
    panel: 'panel42',
    category: 'cancellationAndRefunds',
    heading: 'What are the available modes of payment?',
    detail: 'Currently we offer Debit/Credit Cards, UPI and Net banking as modes of payment.',
  },
  {
    panel: 'panel43',
    category: 'cancellationAndRefunds',
    heading: 'How secure are my payment details and personal information?',
    detail:
      'We strive hard to keep your data secure and personal. For better understanding, you can refer to the privacy policy.',
  },
  {
    panel: 'panel44',
    category: 'cancellationAndRefunds',
    heading: 'Payment failed but the money has been deducted from the account, what should I do?',
    detail: `If the payment fails and money has been deducted from your account. The same should be automatically refunded to you. In case it doesn’t arrive within 24 hours, we advise to contact your bank.`,
  },
  {
    panel: 'panel45',
    category: 'cancellationAndRefunds',
    heading: `I have received a confirmation on the deposit, but the amount has not 
    been credited to my bank account?
    `,
    detail: (
      <>
        <Typography>
          After the deposit has been initiated it will take 5-7 working days to reflect in your bank account. Although
          if the same is not credited within 7 days , kindly reach us out at{' '}
          <a href="mailto:support@photonboxx.com" style={{ textDecoration: 'none', color: '#3366FF' }}>
            {' '}
            support@photonboxx.com
          </a>{' '}
          or raise a ticket through the app.
        </Typography>
      </>
    ),
  },
  {
    panel: 'panel46',
    category: 'cancellationAndRefunds',
    heading: 'In how many days will return pickup be done after cancellation?',
    detail:
      'The return pickup is done as per the pickup slot booked by the customer. Ideally, it should be instantly available but can take upto a maximum of 7 days to schedule a pickup.',
  },
];

export default FAQS_ARRAY;
