import { createSlice } from '@reduxjs/toolkit/dist';
import { dispatch } from '../app/store';

const initialState = {
  isResponsive: window.innerWidth,
};

const responsiveSlicer = createSlice({
  name: 'responsive',
  initialState,
  reducers: {
    setResponsive: (state, action) => {
      state.isResponsive = action.payload;
    },
  },
});

export default responsiveSlicer.reducer;

const { setResponsive } = responsiveSlicer.actions;

export const changeScreenSize = () => {
  try {
    const width = window.innerWidth;
    dispatch(setResponsive(width));
  } catch (error) {
    console.log(error);
  }
};

export const responsiveAction = () => {
  window.addEventListener('resize', () => {
    changeScreenSize();
  });
};

export const removeEvent = () => {
  window.removeEventListener('resize', () => {
    changeScreenSize();
  });
};
