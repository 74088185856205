import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'otpAuth',
          element: (
            <GuestGuard>
              <OtpRegister />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: '/',
      element: <Navigate to="/dashboard/home" replace />,
      index: true,
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <Home /> },
        { path: 'invoice', element: <InvoicePage /> },
        { path: 'order-status', element: <PaymentStatus /> },

        // SUPER ADMIN START
        {
          path: 'superAdmin',
          children: [
            { path: 'generateSubscription', element: <GenerateSubscription /> },
            { path: 'inviteForKyc', element: <InviteForKycForm /> },
            { path: 'inviteForMandate', element: <InviteForMandate /> },
          ],
        },
        // SUPER ADMIN END

        // manageSubscription Routes Start
        {
          path: 'manageSubscription',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard subVia={['PHOTONBOXX']} hasContent>
                  <ManageSubPage />
                </RoleBasedGuard>
              ),
            },
            { path: 'relocation', element: <RelocationAddressPage /> },
            { path: 'upgradeSubscription', element: <UpgradeSubPage /> },
            { path: 'subscriptionChangeStatus', element: <UpgradeSubscriptionResponse /> },
            { path: 'paymentMethodChangeStatus', element: <PaymentMethodResponse /> },
            { path: 'payNowStatus', element: <PayNowStatus /> },
          ],
        },
        // manageSubscription Routes End
        {
          path: 'billings',
          element: <Billings />,
        },
        {
          path: 'kyc',
          element: <Kyc />,
        },
        {
          path: 'systems',
          children: [
            { path: 'subscription', element: <NewSystemRequest /> },
            { path: 'new-system-request', element: <NewSystemRequestPage /> },
            { path: 'address', element: <Address /> },
            { path: 'orderStatus', element: <Billing /> },

            { path: 'createOneTimePayment', element: <CreateJuspayOrder /> },
            { path: 'juspayOrderStatus', element: <JuspayOrderStatusPage /> },
          ],
        },
        {
          path: 'manageUsers/userList',
          element: (
            <RoleBasedGuard roles={['owner', 'admin']} hasContent>
              <UserList />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'manageUsers/inviteUser',
          element: <InviteUser />,
        },
        {
          path: 'settings',
          children: [
            {
              path: '',
              element: <Settings />,
            },
            { path: 'editProfile', element: <EditProfile /> },
            { path: 'notification', element: <Notifications /> },
            { path: 'orderList', element: <OrderList /> },
            { path: 'orderList/orderDetails/:orderID', element: <OrderDetailsPage /> },
            { path: 'viewSubscription', element: <ViewSubscription /> },
            { path: 'allInvoices/:orderID', element: <AllInvoices /> },
          ],
        },
        {
          path: 'support',
          children: [
            { path: '', element: <Support /> },
            { path: 'reportProblem/raiseTicket', element: <RaiseTicket /> },
            { path: 'reportProblem/myTicketList', element: <MyTicketList /> },
            { path: 'reportProblem/myTicketDetsils/:systemID', element: <MyticketDetails /> },
            { path: 'faqs', element: <Faqs /> },
          ],
        },
        // about open
        {
          path: 'about',
          children: [
            { path: '', element: <AboutList /> },
            { path: 'termsAndConditions', element: <TAndC /> },
            { path: 'privacyPolicy', element: <AboutPrivacyPolicy /> },
          ],
        },
        // about close
        // Alert open
        {
          path: 'alert',
          children: [{ path: '', element: <Alerts /> }],
        },
        // Alert Close
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <LogoOnlyLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'due-pay', element: <DuePay /> },
        { path: 'change-pay', element: <ChangePay /> },
        { path: 'payment', element: <PaymentPage /> },
      ],
    },

    // Main Routes
    {
      path: 'docs',
      element: <MainLayout />,
      children: [
        { path: 'terms-and-condition', element: <TermsAndCondition /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

//
const Alerts = Loadable(lazy(() => import('../pages/dashboard/alerts/Alerts')));
//

// About Start
const AboutList = Loadable(lazy(() => import('../pages/dashboard/about/AboutList')));
const TAndC = Loadable(lazy(() => import('../pages/dashboard/about/TAndC')));
const AboutPrivacyPolicy = Loadable(lazy(() => import('../pages/dashboard/about/PrivacyPolicy')));
// About End

// ManageSubscription Start
const ManageSubPage = Loadable(lazy(() => import('../pages/dashboard/manageSubscription/ManageSubPage')));
const UpgradeSubPage = Loadable(lazy(() => import('../pages/dashboard/manageSubscription/UpgradeSubPage')));
const RelocationAddressPage = Loadable(
  lazy(() => import('../pages/dashboard/manageSubscription/RelocationAddressPage'))
);
const UpgradeSubscriptionResponse = Loadable(
  lazy(() => import('../pages/dashboard/manageSubscription/payNowStatus/UpgradeSubscriptionResponse'))
);
const PaymentMethodResponse = Loadable(
  lazy(() => import('../pages/dashboard/manageSubscription/payNowStatus/PaymentMethodResponse'))
);
const PayNowStatus = Loadable(lazy(() => import('../pages/dashboard/manageSubscription/payNowStatus')));
// ManageSubscription End

// //////////////////////////System Requests//////////////////////////////
const NewSystemRequest = Loadable(lazy(() => import('../pages/dashboard/newSystemRequest/NewSystemRequest')));
const Billing = Loadable(lazy(() => import('../pages/dashboard/newSystemRequest/OrderStatus')));

const CreateJuspayOrder = Loadable(lazy(() => import('../pages/dashboard/newSystemRequest/CreateJuspayOrder')));
const JuspayOrderStatusPage = Loadable(lazy(() => import('../pages/dashboard/newSystemRequest/JuspayOrderStatusPage')));

const Address = Loadable(lazy(() => import('../pages/dashboard/newSystemRequest/Address')));

// OTP Authentication
const OtpRegister = Loadable(lazy(() => import('../pages/otpAuth/OtpRegistration')));

// //////////////////////////////////////// Dashboard routes start /////////////////////////////////////
const Home = Loadable(lazy(() => import('../pages/dashboard/home/Home')));
const Billings = Loadable(lazy(() => import('../pages/dashboard/billings/Billings')));
const Kyc = Loadable(lazy(() => import('../pages/dashboard/kyc/Kyc')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/users/userList/UserList')));
const InviteUser = Loadable(lazy(() => import('../pages/dashboard/users/inviteUser/InviteUser')));
// ////////////////////////////////////// Dashboard routes end ////////////////////////////////////////

// //////////////////////////////////Setting routes start //////////////////////////////////////////////
const Settings = Loadable(lazy(() => import('../pages/dashboard/settings/Settings')));
const EditProfile = Loadable(lazy(() => import('../pages/dashboard/settings/editProfile/EditProfile')));
const Notifications = Loadable(lazy(() => import('../pages/dashboard/settings/notifications/Notifications')));
const OrderList = Loadable(lazy(() => import('../pages/dashboard/settings/orderList/OrderList')));
const OrderDetailsPage = Loadable(lazy(() => import('../pages/dashboard/settings/orderDetails/OrderDetailsPage')));
const ViewSubscription = Loadable(lazy(() => import('../pages/dashboard/settings/viewSubscription')));

// //////////////////////////TEST RENDER PAGE BY ROLE ////////////////////////////////////////////////
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));
// /////////////////////////// Test render page by role end //////////////////////////////////////////

// ////////////////////////// Exception routes start /////////////////////////
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
// ////////////////////////// Exception routes end //////////////////////////

// /////////////////////////////// Support //////////////////////////////
const Support = Loadable(lazy(() => import('../pages/dashboard/support/Support')));
const RaiseTicket = Loadable(lazy(() => import('../pages/dashboard/support/ReportProblem/RaiseTicket')));
const MyTicketList = Loadable(lazy(() => import('../pages/dashboard/support/ReportProblem/MyTicketList')));
const MyticketDetails = Loadable(lazy(() => import('../pages/dashboard/support/ReportProblem/MyticketDetails')));
const Faqs = Loadable(lazy(() => import('../pages/dashboard/support/faqs/Faqs')));
// /////////////////////////////// Support end //////////////////////////////

// /////////////////////////////// Terms & conditon //////////////////////////////
const TermsAndCondition = Loadable(lazy(() => import('../pages/TermsAndCondition')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));

// superAdmin
const GenerateSubscription = Loadable(lazy(() => import('../pages/dashboard/superAdmin/GenerateSubscription')));
const InviteForKycForm = Loadable(lazy(() => import('../pages/dashboard/superAdmin/InviteForKyc')));
const InviteForMandate = Loadable(lazy(() => import('../pages/dashboard/superAdmin/InviteForMandate')));

// Inovice
const InvoicePage = Loadable(lazy(() => import('../pages/dashboard/invoice/InvoicePage')));
const AllInvoices = Loadable(lazy(() => import('../pages/dashboard/invoice/allInvoices')));

// upgrade plan

// payment page
const PaymentPage = Loadable(lazy(() => import('../pages/dashboard/payment')));
const DuePay = Loadable(lazy(() => import('../pages/dashboard/payment/DuePay')));
const ChangePay = Loadable(lazy(() => import('../pages/dashboard/payment/ChangePay')));

// new system request routes
const NewSystemRequestPage = Loadable(lazy(() => import('../pages/dashboard/new-system-request')));
const PaymentStatus = Loadable(lazy(() => import('../pages/dashboard/payment-status')));
