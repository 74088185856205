import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (token) => {
  if (!token) {
    return false;
  }
  return true;
};

const setSession = (token) => {
  if (token) {
    localStorage.setItem('token', token);
    axios.defaults.headers.common.token = `${token}`;
  } else {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.token;
  }
};

export { isValidToken, setSession };
