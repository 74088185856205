import styled from '@mui/material/styles/styled';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IndianFlag from '../../assets/svg/IndianFlag.svg';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const OtpRegSection = ({ next, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: yup.object({
      mobileno: yup
        .string()
        .matches(/^[0-9]{10}$/, 'Mobile number is not valid')
        .required('Mobile number is required')
        .min(10, 'Mobile number is too short')
        .max(10, 'Mobile number is too long'),
    }),
    onSubmit: async (values) => {
      try {
        const sendOtpResponse = await axios.post('/v1/auth/send-otp', {
          mobile: `${values.mobileno}`,
        });
        if (sendOtpResponse.status === 200) {
          next(values, false);
        } else {
          enqueueSnackbar(sendOtpResponse.data.message, {
            variant: 'error',
          });
        }
      } catch (err) {
        enqueueSnackbar(err.message || 'Some error occurred, please try again later!', { variant: 'error' });
      }
    },
  });

  const { errors, touched, getFieldProps, handleSubmit, isSubmitting } = formik;

  return (
    <>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Welcome to Photonboxx
              </Typography>
              <Typography sx={{ color: 'text.secondary', mt: 4 }}>
                Please enter your Mobile Number to login or signup
              </Typography>
            </Box>
          </Stack>

          <Stack spacing={3}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 10);
                  }}
                  {...getFieldProps('mobileno')}
                  error={Boolean(touched.mobileno && errors.mobileno)}
                  helperText={touched.mobileno && errors.mobileno}
                  placeholder="Mobile Number"
                  fullWidth
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={IndianFlag} alt="IndianFlag" />
                        <Typography variant="subtitle"> &nbsp; &nbsp; +91&nbsp; &nbsp; &nbsp;</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  // disabled={!formik.isValid}
                  sx={{ mt: 3 }}
                >
                  Submit
                </LoadingButton>
              </Form>
            </FormikProvider>
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              By continuing, you agree to our{' '}
              <Link to="/docs/terms-and-condition" variant="body2" sx={{ color: 'text.primary' }}>
                Terms & Conditions
              </Link>
              ,{' '}
              <Link to="/docs/privacy-policy" variant="body2" sx={{ color: 'text.primary' }}>
                Privacy Policy
              </Link>
            </Typography>
          </Stack>
        </ContentStyle>
      </Container>
    </>
  );
};

OtpRegSection.propTypes = {
  next: propTypes.func,
  data: propTypes.object,
};

export default OtpRegSection;
