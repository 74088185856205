import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Stack, Select, FormControl } from '@mui/material';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import useAuth from '../../../hooks/useAuth';
import { changeRoleAction } from '../../../redux/slices/authSlicer';
import { setPbxOrderId, setOrderedVia, setLinkedId } from '../../../redux/slices';
import useScreenSize from '../../../hooks/useScreenSize';
import axios from '../../../utils/axios';

const SystemSelect = () => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const [orderList, setOrderList] = useState([]);
  const [wrong, setWrong] = useState('');
  const { user } = useAuth();
  const { isResponsive } = useScreenSize();
  const [changeSystem, setChangeSystem] = React.useState('');
  const { orderedId } = useSelector((state) => state.orderedState);
  const navigate = useNavigate();

  const handleChange = (event) => {
    dispatch(setPbxOrderId(event.target.value));
    const selectedSubscription = orderList.find((e) => e.pbxOrderId === event.target.value);
    console.log('selectedSubscription', selectedSubscription);
    dispatch(setOrderedVia(selectedSubscription?.orderedVia));
    dispatch(setLinkedId(selectedSubscription?.linkedId));
  };

  const fetchSystems = async () => {
    try {
      const allSubscriptionRes = await axios.get('/v1/user/products-linked');
      if (allSubscriptionRes.status === 200) {
        if (orderedId === '') {
          dispatch(setPbxOrderId(allSubscriptionRes?.data?.productsLinked[0].pbxOrderId));
          dispatch(setOrderedVia(allSubscriptionRes?.data?.productsLinked[0]?.orderedVia));
          dispatch(setLinkedId(allSubscriptionRes?.data?.productsLinked[0]?.linkedId));
        }
        setOrderList(allSubscriptionRes?.data?.productsLinked);
      } else {
        setWrong('Something went wrong');
      }
    } catch (err) {
      setWrong('something went wrong');
    }
  };

  const navigateToSystemReq = () => {
    // navigate('/dashboard/systems/subscription');
    window.location = '/dashboard/systems/new-system-request';
  };

  useEffect(() => {
    fetchSystems();
  }, []);

  return (
    <>
      {orderList?.length > 0 ? (
        <Select
          value={orderedId}
          margin="none"
          sx={{
            width: isResponsive < 900 ? '100%' : 215,
            height: 45,
            // background: '#D6E4FF',
            borderRadius: '8px',
            outline: 'none',
            color: '#3366FF',
          }}
          onChange={handleChange}
        >
          <MenuItem
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
              '&.Mui-focused': { color: 'red' },
              '&:selection': {
                color: 'red', // specify your desired color here
                background: 'blue', // specify your desired background color here
              },
              '&:first-of-type': { mt: 0 },
              '&:last-of-type': { mb: 0 },
            }}
          >
            Select
          </MenuItem>
          {orderList?.map((e, i) => {
            return (
              <MenuItem
                sx={{
                  mx: 2,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',

                  textTransform: 'capitalize',
                  '&:first-of-type': { mt: 0 },
                  '&:last-of-type': { mb: 0 },
                }}
                key={i}
                value={e?.pbxOrderId}
              >
                <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                  <img src="/assets/icons/selectIconActive.svg" alt="activeIcon" />
                  &nbsp; &nbsp; {e?.systemName}
                </Stack>
              </MenuItem>
            );
          })}
          <MenuItem
            sx={{
              mx: 0,
              my: 0,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
              '&:first-of-type': { mt: 0 },
              '&:last-of-type': { mb: 0 },
            }}
          >
            <Button variant="contained" fullWidth onClick={navigateToSystemReq}>
              Request New System
            </Button>
          </MenuItem>
        </Select>
      ) : null}
    </>
  );
};

SystemSelect.propTypes = {
  isHidden: propTypes.bool,
};

export default SystemSelect;
