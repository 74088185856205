import { createSlice } from '@reduxjs/toolkit/dist';
import { dispatch, getState } from '../app/store';
import FAQS_ARRAY from '../../_mock/_FAQ';

const initialState = {
  isLoading: false,
  isError: null,
  _FAQS: [],
};

const FilterSearchSlicer = createSlice({
  name: 'filterSearch',
  initialState,
  reducers: {
    getFaqsStart: (state) => {
      state.isLoading = true;
      state.isError = null;
    },
    getFaqsSuccess: (state, action) => {
      state.isLoading = false;
      state._FAQS = action.payload;
    },
    getFaqsFailure: (state, action) => {
      state.isLoading = false;
      state.isError = action.payload;
    },
  },
});

export default FilterSearchSlicer.reducer;

const { getFaqsStart, getFaqsSuccess, getFaqsFailure } = FilterSearchSlicer.actions;

export const searchFaqAction = (search) => {
  try {
    const columns = FAQS_ARRAY[0] && Object.keys(FAQS_ARRAY[0]);
    const res = FAQS_ARRAY.filter((row) =>
      columns.some((column) => row[column].toString().toLowerCase().indexOf(search.toLowerCase()) > -1)
    );
    dispatch(getFaqsSuccess(res));
  } catch (error) {
    console.log(error);
  }
};

export const filterFaqAction = (filter) => {
  dispatch(getFaqsStart());
  try {
    if (filter === 'all' || filter === '') {
      dispatch(getFaqsSuccess(FAQS_ARRAY));
    } else {
      const res = FAQS_ARRAY.filter((row) => row.category === filter);
      dispatch(getFaqsSuccess(res));
    }
  } catch (error) {
    console.log(error);
  }
};
