import { createSlice } from '@reduxjs/toolkit/dist';
import axios from 'axios';
import { dispatch } from '../app/store';

const initialState = {
  isLoading: false,
  isError: null,
  isCountry: [],
  isState: [],
  isCity: [],
};

const regionSlicer = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
    setCountry: (state, action) => {
      state.isCountry = action.payload;
    },
    setStates: (state, action) => {
      state.isState = action.payload;
    },
    setCities: (state, action) => {
      state.isCity = action.payload;
    },
  },
});

export default regionSlicer.reducer;

const { setLoading, setError, setCountry, setStates, setCities } = regionSlicer.actions;

export const countryAction = async () => {
  dispatch(setLoading());
  try {
    const res = await axios.get(`https://csc-api.herokuapp.com/region/country`);
    const _country = sortRegion(res.data);
    dispatch(setCountry(_country));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const stateAction = async (cCode) => {
  dispatch(setLoading());
  try {
    const res = await axios.get(`https://csc-api.herokuapp.com/region/states/IN`);
    const _state = sortRegion(res.data);
    dispatch(setStates(_state));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const CityAction = async (sCode) => {
  dispatch(setLoading());
  try {
    const res = await axios.get(`https://csc-api.herokuapp.com/region/city/${sCode}`);
    const _city = sortRegion(res.data);
    dispatch(setCities(_city));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

const sortRegion = (arr) => {
  const Sorts = arr.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    // names must be equal
    return 0;
  });
  return Sorts;
};
