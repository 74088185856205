import { createSlice } from '@reduxjs/toolkit/dist';
import { dispatch } from '../app/store';

const initialState = {
  selectplan: 12,
  couponCode: '',
  couponValidity: '',
};

const planAndCouponCodeSlice = createSlice({
  name: 'planAndCouponCode',
  initialState,
  reducers: {
    setSelectPlan: (state, action) => {
      state.selectplan = action.payload;
    },
    setCouponCode: (state, action) => {
      state.couponCode = action.payload;
    },
    setCouponValidity: (state, action) => {
      state.couponValidity = action.payload;
    },
    resetCouponCode: (state) => {
      state.couponCode = '';
      state.couponValidity = '';
    },
  },
});

export default planAndCouponCodeSlice.reducer;

export const { setSelectPlan, setCouponCode, setCouponValidity, resetCouponCode } = planAndCouponCodeSlice.actions;

export const planAction = (plan) => (dispatch) => {
  dispatch(setSelectPlan(plan));
  dispatch(setCouponCode(''));
};

export const selectPlanAction = (plan) => (dispatch) => {
  dispatch(setSelectPlan(plan));
  dispatch(setCouponCode(''));
};

export const couponCodeAction = (code) => (dispatch) => {
  dispatch(setCouponCode(code));
};

export const couponValidityAction = (validity) => (dispatch) => {
  dispatch(setCouponValidity(validity));
};
