import styled from '@mui/material/styles/styled';
import { Form, FormikProvider, useFormik } from 'formik';
import propTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import OtpInput from 'react18-input-otp';
import CountDown from '../../hooks/CountDown';
import { login } from '../../redux/slices/authSlicer';
import axios from '../../utils/axios';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const VerifyOtpSection = ({ next, prev, data, setData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [otpLoad, setOtpLoad] = useState(false);
  const { minutes, seconds, togglerTimer, runTimer } = CountDown(0.5);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: yup.object({
      otp: yup.string().required('OTP is required').min(6, 'OTP is too short').max(6, 'OTP is too long'),
    }),
    onSubmit: async (values) => {
      try {
        const verifyotpResponse = await axios.post('/v1/auth/verify-otp', {
          mobile: `${values.mobileno}`,
          otp: values.otp,
        });

        if (verifyotpResponse.status === 200 && verifyotpResponse.data.token) {
          login(verifyotpResponse);
          // next(values, true);
          enqueueSnackbar(verifyotpResponse.data.message, { variant: 'success' }); // login
        } else if (verifyotpResponse.status === 200 && verifyotpResponse.data.hasRegistered === false) {
          next({ ...values, token: verifyotpResponse.data.regToken }, false);
          enqueueSnackbar(verifyotpResponse.data.message, { variant: 'success' }); // register
        } else {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
  });

  const { values, errors, touched, setFieldValue, handleSubmit, isSubmitting } = formik;

  useEffect(() => {
    togglerTimer();
    return () => {
      togglerTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendOtp = async () => {
    setOtpLoad(true);
    try {
      const sendOtpResponse = await axios.post('/v1/auth/send-otp', {
        mobile: `${values.mobileno}`,
      });
      if (sendOtpResponse.status === 200) {
        enqueueSnackbar(sendOtpResponse.data.message, {
          variant: 'success',
        });
        setFieldValue('otp', '');
        setOtpLoad(false);
      } else {
        enqueueSnackbar(sendOtpResponse.message, {
          variant: 'error',
        });
        setFieldValue('otp', '');
        setOtpLoad(false);
      }
      togglerTimer();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      setFieldValue('otp', '');
      setOtpLoad(false);
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 4 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Please check your message
              </Typography>
              <Typography sx={{ color: 'text.secondary', mt: 4, fontSize: 14 }}>
                Please enter the 6-digit verification code sent to your phone number +91{values.mobileno}
                <span style={{ cursor: 'pointer', color: '#3366FF' }} onClick={() => prev({ otp: '' })}>
                  &nbsp; Edit number
                </span>
              </Typography>
            </Box>
          </Stack>
          <Stack spacing={3}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <OtpInput
                  isInputNum
                  shouldAutoFocus={true}
                  inputStyle={{
                    padding: 20,
                    borderRadius: 6,
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                  }}
                  focusStyle={{
                    border: '1px solid #3366FF',
                    borderRadius: 6,
                    backgroundColor: '#FFFFFF',
                  }}
                  onChange={(e) => setFieldValue('otp', e)}
                  numInputs={6}
                  value={values.otp}
                  hasErrored="true"
                  errorStyle={{ border: touched.otp && errors.otp ? '1px solid red' : '1px solid #ccc' }}
                  separator={<span>&nbsp; &nbsp;</span>}
                />
                <LoadingButton
                  fullWidth
                  loading={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ mt: 3 }}
                >
                  Verify
                </LoadingButton>
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mt: 4 }}>
                  <Typography variant="body2" textAlign="center" sx={{ color: 'text.secondary' }}>
                    Didn’t receive the code? &nbsp;
                  </Typography>
                  <Typography
                    variant="body2"
                    textAlign="center"
                    color={!runTimer ? 'primary' : 'text.secondary'}
                    sx={{ cursor: 'pointer' }}
                    onClick={!runTimer ? resendOtp : null}
                  >
                    Resend code &nbsp;
                  </Typography>
                  {runTimer ? (
                    <Typography variant="body2" textAlign="center" sx={{ color: 'text.secondary' }}>
                      {minutes}:{seconds}
                    </Typography>
                  ) : null}
                </Stack>
              </Form>
            </FormikProvider>
          </Stack>
        </ContentStyle>
      </Container>
    </>
  );
};

VerifyOtpSection.propTypes = {
  prev: propTypes.func.isRequired,
  next: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  setData: propTypes.func.isRequired,
};

export default VerifyOtpSection;
