// i18n
import './locales/i18n';

// highlight
// import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
// import 'react-image-lightbox/style.css';

// map
// import './utils/mapboxgl';
// import 'mapbox-gl/dist/mapbox-gl.css';

// editor
// import 'react-quill/dist/quill.snow.css';

// slick-carousel
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
// import 'react-lazy-load-image-component/src/effects/opacity.css';
// import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import './styles.css';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

// react query

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
// redux
import { store, persistor } from './redux/app/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Page500 from './pages/Page500';
import FallbackError from './components/FallbackError';

// ----------------------------------------------------------------------

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const queryClient = new QueryClient();

const errorHandler = (error, errorInfo) => {
  console.log('logging', error, errorInfo);
};

root.render(
  // <AuthProvider>
  <ErrorBoundary FallbackComponent={FallbackError} onError={errorHandler}>
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <App />
                  <ReactQueryDevtools initialIsOpen={false} />
                </BrowserRouter>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </HelmetProvider>
        </PersistGate>
      </ReduxProvider>
    </QueryClientProvider>
  </ErrorBoundary>
  // </AuthProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
