import { Outlet, useLocation } from 'react-router-dom';
// @mui

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// components
// import Logo from '../../components/Logo';
//
import Logo from '../../assets/svg/Logo.svg';
import MainHeader from './MainHeader';
import Image from '../../components/Image';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      {/* {!isHome ? (
        <MainFooter />
      ) : ( */}
      <Box
        sx={{
          py: 5,
          textAlign: 'center',
          position: 'relative',
          bgcolor: 'background.default',
        }}
      >
        <Container>
          <Stack display="flex" justifyContent="center" alignItems="center" mb={1}>
            <Image src={Logo} alt="logo" />
          </Stack>
          <Typography variant="caption" component="p">
            © All rights reserved
          </Typography>
        </Container>
      </Box>
      {/* )} */}
    </Stack>
  );
}
