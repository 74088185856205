import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { responsiveAction, removeEvent } from '../redux/slices/responsiveSlicer';

const useScreenSize = () => {
  const { isResponsive } = useSelector((state) => state.responsiveState);

  useEffect(() => {
    responsiveAction();
    return () => {
      removeEvent();
    };
  }, []);

  return {
    isResponsive,
  };
};

export default useScreenSize;
