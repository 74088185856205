import { createSlice } from '@reduxjs/toolkit/dist';
import axios from '../../utils/axios';

const initialState = {
  isAlertLoad: false,
  isAlertError: null,
  alertData: [],
  page: 1,
  rowsPerPage: 5,
  total: 0,
  hasNext: true,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    alertStart: (state) => {
      state.isAlertLoad = true;
    },
    setAlertList: (state, { payload }) => {
      state.isAlertLoad = false;
      state.alertData = payload;
    },
    setAlertListMobile: (state, action) => {
      state.alertData = [...state.alertData, ...action.payload];
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setHasNext: (state, { payload }) => {
      state.hasNext = payload;
    },
    setAlertError: (state, action) => {
      state.isAlertLoad = false;
      state.isAlertError = action.payload;
    },
  },
});

export default alertSlice.reducer;

export const {
  alertStart,
  setAlertList,
  setAlertListMobile,
  setPage,
  setRowsPerPage,
  setHasNext,
  setTotal,
  setAlertError,
} = alertSlice.actions;

export const alertListAction = (email) => {
  return async (dispatch, getState) => {
    try {
      dispatch(alertStart());
      const { page, rowsPerPage } = getState().alertListState;

      const { data } = await axios.get(`/v1/ticket/all-tickets?page=${page}&limit=${rowsPerPage}`);

      dispatch(setAlertList(data.tickets.docs));
      dispatch(setTotal(data.tickets.totalDocs));
      dispatch(setHasNext(data.tickets.hasNextPage));
    } catch (err) {
      dispatch(setAlertError(err));
    }
  };
};

export const alertListActionSm = () => {
  return async (dispatch, getState) => {
    try {
      const { page, rowsPerPage } = getState().alertListState;

      const { data } = await axios.get(`/v1/ticket/all-tickets?page=${page}&limit=${rowsPerPage}`);
      dispatch(setAlertListMobile(data.tickets.docs));
      dispatch(setTotal(data.tickets.totalDocs));
      dispatch(setHasNext(data.tickets.hasNextPage));
    } catch (err) {
      dispatch(setAlertError(err));
    }
  };
};
