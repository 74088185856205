import { createSlice } from '@reduxjs/toolkit/dist';
import { setSession } from '../../utils/jwt';
import axios from '../../utils/axios';
import { dispatch } from '../app/store';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {
    role: 'user',
    ongoingSubscriptions: [],
  },
  method: 'jwt',
};

const authSlicer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setInitialize: (state, action) => {
      const { isAuthenticated, user } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.isInitialized = true;
      state.user = { ...state.user, ...user };
    },
    setLogin: (state, action) => {
      const { user } = action.payload;
      state.isAuthenticated = true;
      state.user = { ...state.user, ...user };
    },
    setLogout: (state) => {
      state.isAuthenticated = false;
      state.user = { ongoingSubscriptions: [] };
    },
    setRegister: (state, action) => {
      const { user } = action.payload;
      state.isAuthenticated = true;
      state.user = { ...state.user, ...user };
    },
    changeRole: (state, action) => {
      state.user.role = action.payload;
    },
  },
});

export default authSlicer.reducer;

export const { setInitialize, setLogin, setLogout, setRegister, changeRole } = authSlicer.actions;

export const initialize = async () => {
  try {
    const token = localStorage.getItem('token');

    if (token) {
      setSession(token);
      const response = await axios.get('/v1/user/get-user');

      const { userDetails } = response.data;
      dispatch(setInitialize({ isAuthenticated: true, user: userDetails }));
    } else {
      dispatch(setInitialize({ isAuthenticated: false, user: null }));
    }
  } catch (err) {
    dispatch(setInitialize({ isAuthenticated: false, user: null }));
  }
};

export const login = async (response) => {
  try {
    // const response = await axios.post('/api/account/login', {
    //   email,
    //   password,
    // });

    const { token } = response.data;
    const user = {};
    // const token = response.data.token;
    setSession(token);

    dispatch(setLogin({ user }));
  } catch (err) {
    console.error(err);
  }
};

export const logout = async () => {
  setSession(null);
  dispatch(setLogout());
  localStorage.clear();
};

export const register = async (fullName, mobile, email, regToken) => {
  try {
    const response = await axios.post('/v1/auth/signup', {
      fullName,
      mobile,
      email,
      token: regToken,
    });
    const { token, user } = response.data;

    localStorage.setItem('token', token);

    dispatch(setRegister({ user }));
    return response;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const changeRoleAction = async (role) => {
  dispatch(changeRole(role));
};
