import styled from '@mui/material/styles/styled';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import propTypes from 'prop-types';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useResponsive from '../../hooks/useResponsive';
import { register } from '../../redux/slices/authSlicer';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 410,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const OtpRegSection = ({ next, prev, data, setPage, setData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useResponsive('up', 'lg');
  const formik = useFormik({
    initialValues: data,
    validationSchema: yup.object({
      fullName: yup
        .string()
        .required('Full Name is required')
        .min(3, 'Full Name is too short')
        .max(100, 'Full Name is too long'),
      email: yup.string().required('Email is required').email('Invalid email'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await register(values.fullName, `${values.mobileno}`, values.email, values.token);

        if (response.status === 200) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const { values, errors, touched, getFieldProps, handleSubmit, isSubmitting } = formik;

  const handleBack = () => {
    setPage(0);
    setData({
      fullName: '',
      email: '',
      mobileno: '',
      otp: '',
    });
  };

  return (
    <>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mb: 3 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5" gutterBottom textAlign="center" sx={{ fontSize: 20 }}>
                Please provide additional information
              </Typography>
              {/* <Typography variant="body1" textAlign="center" sx={{ fontSize: 20, fontWeight: 30 }}>
               
              </Typography> */}
            </Box>
          </Stack>
          <Card>
            <CardContent>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <TextField
                      {...getFieldProps('fullName')}
                      label="Enter full name as per your PAN Card "
                      error={Boolean(touched.fullName && errors.fullName)}
                      helperText={touched.fullName && errors.fullName}
                      variant="outlined"
                      fullWidth
                      autoFocus
                    />

                    <TextField
                      {...getFieldProps('email')}
                      label="Email Address"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      variant="outlined"
                      fullWidth
                    />
                  </Stack>
                  <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                    {/* <Typography
                      onClick={handleBack}
                      variant="body1"
                      textAlign="center"
                      mt={2}
                      color="primary"
                      style={{ cursor: 'pointer' }}
                    >
                      Back
                    </Typography> */}
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      // disabled={!formik.isValid}
                      loading={isSubmitting}
                    >
                      Let's Get Started
                    </LoadingButton>
                  </Box>
                </Form>
              </FormikProvider>
            </CardContent>
          </Card>
        </ContentStyle>
      </Container>
    </>
  );
};

OtpRegSection.propTypes = {
  next: propTypes.func,
  prev: propTypes.func,
  data: propTypes.object,
  setPage: propTypes.func,
};

export default OtpRegSection;
