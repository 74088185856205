import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCollapseItem: false,
};

const isCollapseSlice = createSlice({
  initialState,
  name: 'isCollapseName',
  reducers: {
    setIsCollapse: (state, action) => {
      state.isCollapseItem = action.payload;
    },
  },
});

export default isCollapseSlice.reducer;

export const { setIsCollapse } = isCollapseSlice.actions;
