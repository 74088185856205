import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from '../slices/authSlicer'; // AuthSlice
import responsiveSlice from '../slices/responsiveSlicer'; // ResponsiveSlice
import regionSlicer from '../slices/regionSlicer'; // RegionSlice
import FilterSearchSlicer from '../slices/filterSearchSlicer'; // FilterSearchSlice
import planAndCouponCodeSlice from '../slices/planAndCouponCodeSlice';
import LoaderSlicer from '../slices/LoaderSlicer';
import alertSlicer from '../slices/alertSlicer';
import calculateBillingSlicer from '../slices/calculateBillingSlicer';
import pbxOrderedSlicer from '../slices/pbxOrderedSlicer';
import isCollapseSlicer from '../slices/isCollapseSlicer';
import newSystemSlicer from '../slices/newSystemSlicer';
// slices

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const testPersistConfig = {
  key: 'test',
  storage,
};

const borderRadiusPersistConfig = {
  key: 'borderRadius',
  storage,
};

const authPersistConfig = {
  key: 'auth',
  storage,
};

const planConfig = {
  key: 'plan',
  storage,
};
const subscriptionIdConfig = {
  key: 'subscriptionConfig',
  storage,
};

const rootReducer = combineReducers({
  planState: persistReducer(planConfig, planAndCouponCodeSlice),
  authState: authSlice, // AuthSlice
  responsiveState: responsiveSlice, // ResponsiveSlice
  regionState: regionSlicer, // RegionSlice
  filterSearchState: FilterSearchSlicer, // FilterSearchSlice
  loaderState: LoaderSlicer,
  alertListState: alertSlicer,
  calculateBillingState: calculateBillingSlicer,
  orderedState: persistReducer(subscriptionIdConfig, pbxOrderedSlicer),
  isCollapseState: isCollapseSlicer,
  newSystemState: newSystemSlicer,
});

export { rootPersistConfig, rootReducer };
