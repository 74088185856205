// import { useContext } from 'react';
// //
// import { AuthContext } from '../contexts/JWTContext';
// // import { AuthContext } from '../contexts/Auth0Context';
// // import { AuthContext } from '../contexts/FirebaseContext';
// // import { AuthContext } from '../contexts/AwsCognitoContext';

// // ----------------------------------------------------------------------

// const useAuth = () => {
//   const context = useContext(AuthContext);

//   if (!context) throw new Error('Auth context must be use inside AuthProvider');

//   return context;
// };

// export default useAuth;
import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux'; // useSelector
import { initialize, login, logout, register } from '../redux/slices/authSlicer'; // AuthSlice

const useAuth = () => {
  const { isAuthenticated, isInitialized, user, method } = useSelector((state) => state.authState);

  // useEffect(() => {
  //   if (!isLoggedIn) initialize();
  // }, []);

  return {
    method,
    user,
    login,
    logout,
    register,
    isAuthenticated,
    isInitialized,
    initialize,
  };
};

export default useAuth;
