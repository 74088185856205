import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: 0,
  isPayment: false,
  specification: {
    model: '',
    powerRating: '',
    capacity: '',
    batteryType: '',
    weight: '',
    nickName: '',
  },
  // selectPlan: '',
  tenure: 9,
  billing: {},
  skuId: 'PB001',
  installationAddress: {
    addressType: 'HOME',
    fullName: '',
    phoneNumber: '',
    address: '',
    country: '',
    states: '',
    city: '',
    pincode: '',
  },
  getInstallAddress: {
    getCountry: null,
    getState: null,
    getCity: null,
  },

  billingAddress: {
    fullName: '',
    phoneNumber: '',
    address: '',
    country: '',
    states: '',
    city: '',
    pincode: '',
    gstinNo: '',
  },
  getBillingAddress: {
    getCountry: null,
    getState: null,
    getCity: null,
  },
  checked: false,
  couponCode: '',
};

const newSystemSlicer = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSystemSpec: (state, action) => {
      state.specification = {
        ...state.specification,
        ...action.payload,
      };
    },
    setNickName: (state, action) => {
      state.nickName = action.payload;
    },
    setTenure: (state, action) => {
      state.selectPlan = action.payload;
    },
    setBilling: (state, action) => {
      state.billing = action.payload;
    },
    setInstallationAddress: (state, action) => {
      state.installationAddress = {
        ...state.installationAddress,
        ...action.payload,
      };
    },
    setInstallAddressAutocomplate: (state, action) => {
      state.getInstallAddress = {
        ...state.getInstallAddress,
        ...action.payload,
      };
    },
    setBillingAddress: (state, action) => {
      state.billingAddress = { ...state.billingAddress, ...action.payload };
    },
    setBillingAddressAutocomplate: (state, action) => {
      state.getBillingAddress = {
        ...state.getBillingAddress,
        ...action.payload,
      };
    },
    setChecked: (state, action) => {
      state.checked = action.payload;
    },
    setIsPayment: (state, action) => {
      state.isPayment = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
  },
});

export default newSystemSlicer.reducer;

export const {
  setSystemSpec,
  setInstallationAddress,
  setBillingAddress,
  setTenure,
  setNickName,
  setInstallAddressAutocomplate,
  setBillingAddressAutocomplate,
  setChecked,
  setIsPayment,
  setStep,
} = newSystemSlicer.actions;
