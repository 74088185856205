import { createSlice } from '@reduxjs/toolkit/dist';
import axios from '../../utils/axios';

const initialState = {
  billing: {
    couponCode: '',
    couponValidity: '',
    discountPercentage: 0,
    couponDiscount: 0,
    couponDiscountAmount: 0,
    discountAvailableOnBasePrice: 0,
    subtotal: 0,
    taxAmount: 0,
    monthlyBill: 0,
  },
};

const calculateSlice = createSlice({
  name: 'calculate',
  initialState,
  reducers: {
    setBilling: (state, { payload }) => {
      state.billing = payload;
    },
  },
});

export default calculateSlice.reducer;

export const { setBilling } = calculateSlice.actions;
