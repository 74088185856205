// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  otpRegister: path(ROOTS_AUTH, '/otpAuth'),
  login: path(ROOTS_AUTH, '/otpAuth'),
  register: path(ROOTS_AUTH, '/register'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    // superAdmin
    generateSubscription: path(ROOTS_DASHBOARD, '/superAdmin/generateSubscription'),

    // Alert Start
    alert: path(ROOTS_DASHBOARD, '/alert'),

    // Alert End

    // ManageSubscription Start
    manageSubscription: path(ROOTS_DASHBOARD, '/manageSubscription'),
    relocation: path(ROOTS_DASHBOARD, '/manageSubscription/relocation'),
    upgradeSubscription: path(ROOTS_DASHBOARD, '/manageSubscription/upgradeSubscription'),

    // ManageSubscription End

    about: path(ROOTS_DASHBOARD, '/about'),
    termsAndConditions: path(ROOTS_DASHBOARD, '/about/termsAndConditions'),
    privacyPolicy: path(ROOTS_DASHBOARD, '/about/privacyPolicy'),

    // //////////////////////////settings
    account: path(ROOTS_DASHBOARD, '/settings'),
    editProfile: path(ROOTS_DASHBOARD, '/settings/editProfile'),
    notification: path(ROOTS_DASHBOARD, '/settings/notification'),
    orderList: path(ROOTS_DASHBOARD, '/settings/orderList'),
    orderDetails: path(ROOTS_DASHBOARD, '/settings/orderList/orderDetails'),
    // /////////////////////////// close settings

    home: path(ROOTS_DASHBOARD, '/home'),
    billings: path(ROOTS_DASHBOARD, '/billings'),
    kyc: path(ROOTS_DASHBOARD, '/kyc'),
    systems: path(ROOTS_DASHBOARD, '/systems/new-system-request'),
    users: path(ROOTS_DASHBOARD, '/manageUsers/userList'),

    inviteUser: path(ROOTS_DASHBOARD, '/manageUsers/inviteUser'),

    support: path(ROOTS_DASHBOARD, '/support'),
    reportProblem: path(ROOTS_DASHBOARD, '/support/reportProblem/raiseTicket'),
    myTicketList: path(ROOTS_DASHBOARD, '/support/reportProblem/myTicketList'),
    myTicketDetails: path(ROOTS_DASHBOARD, '/support/reportProblem/myTicketDetsils'),
    faqs: path(ROOTS_DASHBOARD, '/support/faqs'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },

  // ////////////////////////////////////////////////////////////////////////////////

  // ////////////////////////////////////////////////////////////////////////////////
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
