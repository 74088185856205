import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orderedId: '',
  orderedVia: '',
  linkedId: '',
};

const orderedIdSlicer = createSlice({
  name: 'products-linked',
  initialState,
  reducers: {
    setPbxOrderId: (state, action) => {
      state.orderedId = action.payload;
    },
    setOrderedVia: (state, action) => {
      state.orderedVia = action.payload;
    },
    setLinkedId: (state, action) => {
      state.linkedId = action.payload;
    },
  },
});

export default orderedIdSlicer.reducer;

export const { setPbxOrderId, setOrderedVia, setLinkedId } = orderedIdSlicer.actions;
