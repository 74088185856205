import { createSlice } from '@reduxjs/toolkit/dist';

const initialState = {
  getLoader: false,
};

const LoaderSlice = createSlice({
  name: 'loaders',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.getLoader = action.payload;
    },
  },
});

export default LoaderSlice.reducer;

export const { setLoader } = LoaderSlice.actions;
