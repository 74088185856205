// routes
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  home: getIcon('ic_home'),
  manageSubIcon: getIcon('ic_devices'),
  systemIcon: getIcon('ic_systemRequestIcon'),
  billings: getIcon('ic_billings'),
  kyc: getIcon('ic_kyc'),
  systems: getIcon('ic_systems'),
  users: getIcon('ic_users'),
  account: getIcon('ic_account'),
  support: getIcon('ic_support'),
  about: getIcon('ic_about'),
  alert: getIcon('ic_alerts'),
};

const NavConfig = () => {
  const { user } = useAuth();
  const { orderedVia } = useSelector((state) => state.orderedState);

  const config = [
    {
      items: [
        { title: 'Home', path: PATH_DASHBOARD.general.home, icon: ICONS.home },

        ...(orderedVia === 'PHOTONBOXX'
          ? [
              {
                title: 'Manage Subscriptions',
                path: PATH_DASHBOARD.general.manageSubscription,
                icon: ICONS.manageSubIcon,
              },
            ]
          : []),

        { title: 'Request New System', path: PATH_DASHBOARD.general.systems, icon: ICONS.systemIcon },
        { title: 'KYC Registration', path: PATH_DASHBOARD.general.kyc, icon: ICONS.kyc },
        // { title: 'Alerts', path: PATH_DASHBOARD.general.alert, icon: ICONS.alert },

        ...(user?.role === 'owner' || user?.role === 'admin'
          ? [{ title: 'Manage Users', path: PATH_DASHBOARD.general.users, icon: ICONS.users }]
          : []),

        { title: 'Account', path: PATH_DASHBOARD.general.account, icon: ICONS.account },
        { title: 'Support', path: PATH_DASHBOARD.general.support, icon: ICONS.support },
        { title: 'About', path: PATH_DASHBOARD.general.about, icon: ICONS.about },
      ],
    },
  ];
  return config;
};

export default NavConfig;
